import React, { useContext } from "react"
import "./layout.css"
import { makeStyles } from "@material-ui/core/styles"
import Footer from "./footer/footer"
import Navbar from "./navbar/navbar"
import BottombarMobile from "./navbar/bottombarMobile"
import { Container, Toolbar } from "@material-ui/core"
import { ScreenContext } from "../context/screenContext"

const useStyles = makeStyles(theme => ({
  main: {
    flex: 1,
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
  },
  mainMobile: {
    flex: 1,
    width: "100%",
    marginTop: "10px",
    marginBottom: "10px",
    marginLeft: "5px",
    marginRight: "5px",
    padding: "0px"
  }
}))

const Layout = ({ activePage, children }) => {
  const classes = useStyles()
  const screen = useContext(ScreenContext)

  return (
    <div className="site">
      <Navbar selectedItem={activePage} />
      <Toolbar />

      <Container className={screen.mobile? classes.mainMobile: classes.main}>{children}</Container>

      {screen.tablet ? (
        <BottombarMobile selectedItem={activePage} />
      ) : (
        <Footer />
      )}
    </div>
  )
}

export default Layout
