import React, { useContext, useState } from 'react'
import { Button } from "@material-ui/core"
import { AppContext } from '../context/appContext';
import CKDStageDialog from './ckdStageDialog';

const StageSelector = () => {
    const { CKDStage } = useContext(AppContext)
    const [openStageDialog, setOpenStageDialog] = useState(false)

    if (CKDStage) {
        return (
          <>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpenStageDialog(true)}
            >
              {CKDStage}
            </Button>
            <CKDStageDialog open={openStageDialog} setOpen={setOpenStageDialog} />
          </>
        )
      } else return null
}

export default StageSelector
