import React, { useContext } from "react"
import { AppContext } from "../context/appContext"
import { FoodCheckContext } from "../context/foodCheckContext"
import initialNutrientSelection from "./tools/tool/nutritionTools/nutrientTable/nutrientsStandard"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core"
import { HealthCheckContext } from "../context/healthCheckContext"
import Image from "gatsby-image"
import { ScreenContext } from "../context/screenContext";

const CKDStageDialog = ({ open, setOpen }) => {
  const { CKDStage, setCKDStage, allStages } = useContext(AppContext)
  const { setStatus } = useContext(HealthCheckContext)
  const foodCheckTool = useContext(FoodCheckContext)
  const screen = useContext(ScreenContext)

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      fullScreen={screen.mobile ? true : false}
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>Select Stage of Kidney Disease</DialogTitle>
      <DialogContent>
        <List>
          {allStages.map((stage, index) => {
            return (
              <ListItem
                alignItems="flex-start"
                button
                key={index}
                selected={stage.stageCKD === CKDStage}
                onClick={() => {
                  setCKDStage(stage.stageCKD)
                  foodCheckTool.setSelectedNutrients(
                    initialNutrientSelection(stage.stageCKD, screen.mobile)
                  )
                  setStatus("start")
                  setOpen(false)
                }}
              >
                <ListItemAvatar style={{ height: "80px", width: "100px" }}>
                  <Image fluid={stage.image.childImageSharp.fluid} />
                </ListItemAvatar>
                <ListItemText
                  style={{ marginLeft: "15px" }}
                  primary={stage.stageCKD}
                  secondary={stage.descriptionCKD}
                />
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
    </Dialog>
  )
}

export default CKDStageDialog
