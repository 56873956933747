const initialNutrientSelection = (stage, mobile) => {
  if (mobile) {
    if (stage === "Early Stage Kidney Disease" || stage === "(Post) Transplant") {
      return ["healthLevel", "naLevel", "pLevel"]
    } else if (stage === "Late Stage Kidney Disease") {
      return ["proteinLevel", "naLevel", "pLevel"]
    } else if (stage === "Dialysis") {
      return ["naLevel", "kLevel", "pLevel"]
    }
  }
  else {
    if (stage === "Early Stage Kidney Disease" || stage === "(Post) Transplant") {
      return ["healthLevel", "naLevel", "pLevel", "fibreLevel"]
    } else if (stage === "Late Stage Kidney Disease") {
      return ["healthLevel", "proteinLevel", "naLevel", "pLevel"]
    } else if (stage === "Dialysis") {
      return ["healthLevel", "naLevel", "kLevel", "pLevel"]
    }
  }
}

export default initialNutrientSelection
