import React, { useContext } from "react"
import { Link } from "gatsby"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { AppContext } from "../../context/appContext"

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: "auto",
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
    borderTop: "1px solid lightgray",
  },
  footerItem: {
    marginRight: "10px",
  },
}))

const Footer = () => {
  const classes = useStyles()

  const { setAboutTabIndex } = useContext(AppContext)

  return (
    <Toolbar variant="dense" className={classes.footer}>
      <Typography variant="subtitle2" className={classes.footerItem}>
        kidney24.com
      </Typography>

      <Typography variant="subtitle2" className={classes.footerItem}>
        <Link to="/about" onClick={() => setAboutTabIndex(0)}>
          Legal Information
        </Link>
      </Typography>
      <Typography variant="subtitle2" className={classes.footerItem}>
        <Link to="/about" onClick={() => setAboutTabIndex(1)}>
          Data Privacy
        </Link>
      </Typography>
      <Typography variant="subtitle2" className={classes.footerItem}>
        <Link to="/about" onClick={() => setAboutTabIndex(2)}>
          Contact
        </Link>
      </Typography>
    </Toolbar>
  )
}

export default Footer
