import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"
import LibraryBooks from "@material-ui/icons/LibraryBooks"
import Restaurant from "@material-ui/icons/Restaurant"
import Ballot from "@material-ui/icons/Ballot"
import Info from "@material-ui/icons/Info"
import { navigate } from "gatsby"

const useStyles = makeStyles({
  stickToBottom: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    zIndex: 10,
  },
})

const BottombarMobile = ({ selectedItem }) => {
  const classes = useStyles()

  return (
    <BottomNavigation
      className={classes.stickToBottom}
      value={selectedItem}
      onChange={(event, newValue) => {
        if (newValue === "knowledge") {
          navigate("/knowledge")
        } else if (newValue === "nutrients") {
          navigate("/nutrients")
        } else if (newValue === "healthAdvisor") {
          navigate("/healthAdvisor")
        } else if (newValue === "about") {
          navigate("/about")
        }
      }}
      showLabels
    >
      <BottomNavigationAction
        value="knowledge"
        label="Knowledge"
        icon={<LibraryBooks />}
      />
      <BottomNavigationAction
        value="nutrients"
        label="Nutrients"
        icon={<Restaurant />}
      />
      <BottomNavigationAction
        value="healthAdvisor"
        label="Health Check"
        icon={<Ballot />}
      />
      <BottomNavigationAction value="about" label="About" icon={<Info />} />
    </BottomNavigation>
  )
}

export default BottombarMobile
