import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { navigate } from "gatsby"
import { CssBaseline, ButtonBase } from "@material-ui/core"
import { ScreenContext } from "../../context/screenContext"
import StageSelector from "../stageSelector";

const queryLogo = graphql`
  query logo {
    file(relativePath: { eq: "logo.svg" }) {
      publicURL
    }
  }
`

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
  },
  menuItem: {
    "&:hover": {
      color: "theme.palette.neutral.darker",
    },
    "&$disabled": {
      backgroundColor: theme.palette.neutral.lighter,
      color: theme.palette.neutral.main,
    },
    marginRight: "10px",
  },
  disabled: {},
  title: {
    color: theme.palette.primary.darker,
    marginRight: "40px",
    userSelect: "none",
  },
  logo: {
    height: "35px",
    marginRight: "15px",
    opacity: "0.8",
  },
}))

const Navbar = ({ selectedItem }) => {
  const classes = useStyles()
  const screen = useContext(ScreenContext)

  const {
    file: { publicURL: logoURL },
  } = useStaticQuery(queryLogo)

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar elevation={screen.mobile? 0: 1} position={screen.mobile? "absolute": "fixed"} className={classes.appBar}>
        <Toolbar>
          <ButtonBase onClick={event => navigate("/")}>
            <img src={logoURL} alt="logo" className={classes.logo} />
            <Typography variant="h5" className={classes.title}>
              Kidney24
            </Typography>
          </ButtonBase>

          <div style={{flexGrow: 2}}>
            {!screen.tablet ? (
              <>
              <Button
                disableRipple
                onClick={() => navigate("/knowledge")}
                disabled={selectedItem === "knowledge"}
                classes={{ root: classes.menuItem, disabled: classes.disabled }}
              >
                Knowledge
              </Button>

              <Button
                disableRipple
                onClick={() => navigate("/nutrients")}
                disabled={selectedItem === "nutrients"}
                classes={{ root: classes.menuItem, disabled: classes.disabled }}
              >
                Nutrients
              </Button>

              <Button
                disableRipple
                onClick={() => navigate("/healthAdvisor")}
                disabled={selectedItem === "healthAdvisor"}
                classes={{ root: classes.menuItem, disabled: classes.disabled }}
              >
                Health Advisor
              </Button>
              </>
            ) : null}
          </div>

          {selectedItem!=="home" ? <StageSelector/> : null}
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default Navbar
